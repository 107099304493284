import componentsCheckoutApplePayButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/checkout/applePayButton"
import componentsCmsMegaMenu from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/megaMenu"
import componentsProductBadge from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/badge"
import componentsProductDetails from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/details"
import componentsProductUpsell from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/upsell"
import componentsVfButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/button"
import componentsVfColorPicker from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/colorPicker"
import componentsVfHeader from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/header"
import componentsVfMediaGallery from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/mediaGallery"
import componentsVfSearch from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/search"
import componentsVfSizePicker from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/sizePicker"
import pagesPdp from "/opt/build/repo/brands/timberland/regions/nora/config/app/pages/pdp"
    
export default {"components":{"checkout":{"applePayButton":componentsCheckoutApplePayButton},"cms":{"megaMenu":componentsCmsMegaMenu},"product":{"badge":componentsProductBadge,"details":componentsProductDetails,"upsell":componentsProductUpsell},"vf":{"button":componentsVfButton,"colorPicker":componentsVfColorPicker,"header":componentsVfHeader,"mediaGallery":componentsVfMediaGallery,"search":componentsVfSearch,"sizePicker":componentsVfSizePicker}},"pages":{"pdp":pagesPdp}}
