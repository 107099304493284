import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    box: 'rounded-sm px-2 text-sm',
    disabled: 'bg-grey-90 b-2 b-grey-30 c-grey-30 dark',
    selected: 'bg-white b-2 b-current c-black fw-bold dark:(bg-black c-white)',
    sizeMd: 'min-h-12 min-w-19.5',
    unavailable: 'bg-cross bg-grey-90 b-current c-grey-30 hover:(b-2 b-black) dark:(bg-grey-10 c-grey-70 hover:b-white)',
    unavailableSelected: 'bg-cross bg-white b-2 b-current c-black dark:(bg-black c-white)',
    unselected: 'bg-grey-90 b-2 b-grey-90 c-black hover:b-current dark:(bg-grey-10 b-grey-10 c-white)'
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
