import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  bulletin: {
    brandClasses: {
      list: 'list-disc space-y-4'
    }
  }
} satisfies RecursivePartial<ProductConfig['details']>
