<template>
  <video
    ref="videoRef"
    itemscope
    itemtype="https://schema.org/VideoObject"
    :muted="isAutoplayEnabled"
    :playsinline="isAutoplayEnabled"
    :autoplay="isAutoplayEnabled"
    :loop
    :controlslist
    preload="metadata"
    data-test-id="base-video"
    @play="onPlay"
    @playing="preventGtmEvent = true"
    @pause="onPause"
    @ended="onEnded"
  >
    <meta v-for="[itemprop, content] in metas" :key="itemprop" :itemprop :content>
    <source :src :type>
  </video>
</template>

<script lang="ts" setup>
import type { VideoHTMLAttributes } from 'vue'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { meta, src, autoplay } = defineProps<BaseVideoPropType>()

const videoRef = ref()
const attrs = useAttrs() as VideoHTMLAttributes
const { $gtm } = useNuxtApp()
const preventGtmEvent = ref(false)
const isAutoplayEnabled = autoplay || meta?.autoplay
const paused = ref(!isAutoplayEnabled)

const metas = computed(() => [
  ['contentUrl', src],
  ...(meta
    ? [
        ['datePublished', meta.date],
        ['uploadDate', meta.date],
        ['description', meta.description],
        ['name', meta.title]
      ]
    : []),
  ['thumbnailUrl', attrs.poster]
].filter(([_, v]) => v))

const onPlay = (e: Event) => {
  if (!preventGtmEvent.value && !isAutoplayEnabled) $gtm?.push('video.onPlay', e.target, meta?.title)
  preventGtmEvent.value = false
  paused.value = false
}

const onPause = (e: Event) => {
  const target = e.target as HTMLVideoElement
  if (!target.seeking && !isAutoplayEnabled) {
    preventGtmEvent.value = false
    if (target.duration !== target.currentTime) $gtm?.push('video.onPause', target, meta?.title)
    paused.value = true
  }
}

const onEnded = (e: Event) => {
  $gtm?.push('video.onComplete', e.target, meta?.title)
}

defineExpose({
  play: () => videoRef.value.play(),
  pause: () => videoRef.value.pause(),
  toggle: () => videoRef.value.paused ? videoRef.value.play() : videoRef.value.pause(),
  paused,
  src
})
</script>
